import {useRef} from "react"
import { Center, Title, Box, Paper, Text, TextInput, Button, Modal, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconCircleCheckFilled } from '@tabler/icons-react';
import { useState } from "react";
import { FullStory } from '@fullstory/browser';


function UpdatesPage() {
  const [opened, { open, close }] = useDisclosure(false);
  const [formDisabled, setDisabled] = useState(false);
  const inputRef = useRef(null);

  const handleClick = async () => {
    const text = inputRef.current.value.trim();
    if (!text) {
      return;
    }
    open();
    setDisabled(true);
    FullStory('trackEvent', { name: 'registered_email', properties: {} })
    try {
      const response = await fetch('/api/report_email', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: text }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      console.log('API response:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
    <Box bg="var(--mantine-color-gray-light)" style={{ minHeight: '100vh' }}>
      <Center>
        <Paper shadow="sm" withBorder mt="lg" style={{ width: '600px' }}>
          <Box px="xl" py="lg">
            <Center><Title order={3} pb="lg">First chapter done </Title></Center>
            <Text pb="sm">I hope you enjoyed the first chapter! I’m hard at work creating more chapters for you to enjoy. </Text>
            <Text>Want to be notified when the next chapter is released? Enter your email below:</Text>
            <TextInput ref={inputRef} label="Email" labelProps="" placeholder="me@gmail.com" mt="xs" disabled={formDisabled}/>
            <Center mt="sm">
              <Button variant="filled" size="md" disabled={formDisabled} onClick={handleClick}>Submit</Button>
            </Center>
          </Box>
        </Paper>
      </Center>
    </Box>
    <Modal opened={opened} onClose={close} withCloseButton={false}>
      <Group>
        <IconCircleCheckFilled color="teal"/>
        <Text>Email Registered</Text>
      </Group>
      <Text mt="md">Thank you for your support! You'll be the first to know when new chapters are available.</Text>

    </Modal>
    </>
  );
}

export default UpdatesPage;
