import { Center, Box, Paper } from '@mantine/core';

function PrivacyPage() {
  const htmlContent = `
    <h1 class="text-center">Privacy policy</h1>

    <p>In this policy, we lay out: what data we collect and why; how your data is handled; and your rights with respect to your data.</p>

    <h2>What we collect and why</h2>

    <p>Our guiding principle is to collect only what we need. Here’s what that means in practice:</p>

    <h3>Identity & access</h3>

    <p>When you sign up, we ask for and keep your email address. When signing up with Google we get the name and email
        from Google, and keep them in our records. This information is used to log you in and send you product updates
        and other essential information.</p>

    <p>We don't sell or give your personal information to third parties, and we won’t use your name in marketing statements without your permission either.</p>

    <h3>Billing information</h3>

    <p>If you sign up for a paid product, you will be asked to provide your payment information and billing address. Credit card information is submitted directly to our payment processor and doesn’t hit our servers. We might store a record of the payment transaction, including the last 4 digits of the credit card number, for purposes of account history, invoicing, and billing support. We might store your billing address so we can charge you for service, calculate any sales tax due, send you invoices, and detect fraudulent credit card transactions. We might occasionally use aggregate billing information to guide our marketing efforts.</p>

    <h3>Product interactions</h3>

    <p>We store on our servers the content that you upload or receive or maintain in your product accounts. This is so you can use our products as intended. </p>

    <h3>Geolocation data</h3>

    <p>We log all account access by full IP address for security and fraud prevention purposes.</p>

    <h3>Website interactions</h3>

    <p>We collect information about your browsing activity for analytics and statistical purposes such as conversion rate testing and experimenting with new product designs. This includes, for example, your browser and operating system versions, your IP address, which web pages you visited and how long they took to load, and which website referred you to us. If you have an account and are signed in, these web analytics data are tied to your IP address and user account until your account is no longer active. The web analytics we use are described further in the Advertising and Cookies section.</p>

    <h3>Cookies</h3>

    <p>
        We use persistent first-party cookies for the site itself, and for the usage of the analytics
        products "FullStory" and "Google Analytics". Analytic products enable us to understand how users use our site.
        Our usage of 3rd party products maintains the anonymity of our users, as they are configured to
        not keep IP addressed or any other personal information.
    </p>

    <h3>Voluntary correspondence</h3>

    <p>When you email us with a question or to ask for help, we keep that correspondence, including your email address, so that we have a history of past correspondence to reference if you reach out in the future.</p>

    <h2>When we access or share your information</h2>

    <p>We might look at usage information that you entered in order to help you troubleshoot or squash a software bug.</p>

    <p>We aggregate all users' responses and inputs in order to improve the accuracy of our systems.</p>

    <p><b>When required under applicable law.</b></p>

    <p>Levelang is developed in Israel, all the data and computing infrastructure are located in the U.S.</p>

    <ul>
        <li>Requests for user data. Our policy is to not respond to government requests for user data unless we are compelled by legal process or in limited circumstances in the event of an emergency request. However, if law enforcement authorities have the necessary warrant, criminal subpoena, or court order requiring us to share data, we must comply. It is Levelang's policy to notify affected users before we share data unless we are legally prohibited from doing so, and except in some emergency cases.</li>
        <li>Preservation requests. Similarly, Levelang's policy is to comply with requests to preserve data only if compelled by an official legal process. We do not share preserved data unless required by law or compelled by a court order that we choose not to appeal. Furthermore, unless we receive a proper warrant, court order, or subpoena before the required preservation period expires, we will destroy any preserved copies of customer data at the end of the preservation period.</li>
        <li>If we are audited by a tax authority, we may be required to share billing-related information. If that happens, we will share only the minimum needed.</li>
    </ul>

    <h2>Your rights with respect to your information</h2>

    <p>We strive to apply the same data rights to all customers, regardless of their location. Some of these rights include:</p>

    <ul>
        <li><b>Right to Know.</b> You have the right to know what personal information is collected, used, shared or sold. We outline both the categories and specific bits of data we collect, as well as how they are used, in this privacy policy.</li>
        <li><b>Right of Access.</b> This includes your right to access the personal information we gather about you, and your right to obtain information about the sharing, storage, security and processing of that information.</li>
        <li><b>Right to Correction.</b> You have the right to request correction of your personal information.</li>
        <li><b>Right to Erasure / “To Be Forgotten”.</b> This is your right to request, subject to certain limitations under applicable law, that your personal information be erased from our possession and, by extension, from all of our service providers. Fulfillment of some data deletion requests may prevent you from using our services because our applications may then no longer work. In such cases, a data deletion request may result in closing your account.</li>
        <li><b>Right to Complain.</b> You have the right to make a complaint regarding our handling of your personal information with the appropriate supervisory authority.</li>
        <li><b>Right to Restrict Processing.</b> This is your right to request restriction of how and why your personal information is used or processed, including opting out of sale of personal information. (Again: we do not sell your personal data.)</li>
        <li><b>Right to Object.</b> You have the right, in certain situations, to object to how or why your personal information is processed.</li>
        <li><b>Right to not Be Subject to Automated Decision-Making.</b> You have the right to object to and prevent any decision that could have a legal or similarly significant effect on you from being made solely based on automated processes. This right is limited if the decision is necessary for performance of any contract between you and us, is allowed by applicable law, or is based on your explicit consent.</li>
        <li><b>Right to Non-Discrimination.</b> We do not and will not charge you a different amount to use our products, offer you different discounts, or give you a lower level of customer service because you have exercised your data privacy rights. However, the exercise of certain rights may, by virtue of your exercising those rights, prevent you from using our Services.</li>
    </ul>

    <p>If you have questions about exercising these rights or need assistance, please contact us. If an authorized agent is corresponding on your behalf, we will need written consent with a signature from the account holder before proceeding.</p>

    <p>If you are in the EU or UK, you can contact your data protection authority to file a complaint or learn more about local privacy laws.</p>

    <h2>How we secure your data</h2>

    <p>All sensitive communication is encrypted with SSL/HTTPS. This includes user registration, log-in, and anything related to payments. Those are handled directly and secured by our payment processor (Gumroad), and user registration and log in processor (Google). This information does not go through our servers, we are only provided with the user names and emails, which are kept in our database. All the communication that is not related to payments and user registration and log in does not include sensitive information and is not encrypted.</p>

    <h2>What happens when you delete content in your product accounts</h2>

    <p>We are happy to delete your data when requested. Please contact us to do so.</p>

    <h2>Changes & questions</h2>

    <p>We may update this policy as needed when our product changes or to comply with relevant regulations and reflect any new practices. Users will be notified when we make a change that is significant in our opinion, when we think it is highly important for user to be made aware of the changes.</p>

    <p>Have any questions, comments, or concerns about this privacy policy, your data, or your rights with respect to your information? Please get in touch with Ehud Barnea by emailing ehud@levelang.com and we’ll be happy to try to answer them!</p>

    <p>This document was adapted from the <a href="https://github.com/basecamp/policies">Basecamp open-source policies</a> / <a href="https://creativecommons.org/licenses/by/4.0/">CC BY 4.0</a>.</p>
  `;

  return (
    <>
    <Box bg="var(--mantine-color-gray-light)" style={{ minHeight: '100vh' }}>
      <Center>
        <Paper shadow="sm" withBorder mt="lg" mb="xl" style={{ width: '1000px' }}>
          <Box px="xl" pb="xl">
            <Box dangerouslySetInnerHTML={{ __html: htmlContent }} />
          </Box>
        </Paper>
      </Center>
    </Box>
    </>
  );
}

export default PrivacyPage;
