import { useState, useEffect, useRef } from "react";
import { Paper, Text, Center, Box, Image, Button, Title, Group, ActionIcon, Menu, rem, Modal, TextInput, Loader, Stack  } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { IconChevronLeft, IconChevronRight, IconPlayerPlay, IconSquareCheck, IconSquare, IconCircleCheckFilled } from '@tabler/icons-react';
import itemScreens from './itemScreens.json';

export const localData = {
    set(key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    get(key) {
        const stored = localStorage.getItem(key);
        return stored == null ? undefined : JSON.parse(stored);
    },
    remove(key) {
        localStorage.removeItem(key);
    }
  };


const AudioPlayer = ({ audioUrl, canAutoPlay }) => {
  const audioRef = useRef(null);

  const playAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  // auto play audio when page loads
  useEffect(() => {
    if (canAutoPlay) {
      playAudio();
    }
  }, [audioUrl]);

  return (
    <>
      <ActionIcon variant="outline" color="black" aria-label="play" radius="xl" size="lg" onClick={playAudio}>
        <IconPlayerPlay/>
      </ActionIcon>
      <audio ref={audioRef} key={audioUrl}>
        <source src={audioUrl}></source>
      </audio>
    </>
  );
}

const LevelChangeButton = ({  }) => {

  const [opened, { open, close }] = useDisclosure(false);
  const emailRef = useRef(null);
  const levelRef = useRef(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleLevelRequest = async () => {
    const emailText = emailRef.current.value;
    const levelText = levelRef.current.value;

    try {
      const response = await fetch('/api/report_level_request', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: emailText, level: levelText }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const result = await response.json();
      console.log('API response:', result);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <>
      <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button variant="outline" color="black" radius="xl">
              Change Level
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Label>Adjust text level</Menu.Label>
            <Menu.Item leftSection={<IconSquareCheck style={{ width: rem(14), height: rem(14) }} />}>
              Level 1
            </Menu.Item>
            <Menu.Item leftSection={<IconSquare style={{ width: rem(14), height: rem(14) }}/>} onClick={open}>
              Level 2
            </Menu.Item>
            <Menu.Item leftSection={<IconSquare style={{ width: rem(14), height: rem(14) }} />} onClick={open}>
              Level 3
            </Menu.Item>
          </Menu.Dropdown>
      </Menu>
      <Modal opened={opened} onClose={close} title="More Levels Coming Soon!">
            `<Text>We're developing more levels.  Enter your email and your level to be notified when they're ready.</Text>
            <Text mt="sm">Thank you for your patience and interest!</Text>
            <TextInput disabled={formSubmitted} ref={emailRef} label="Email" labelProps="" placeholder="me@gmail.com" mt="xs"/>
            <TextInput disabled={formSubmitted} ref={levelRef} label="Level" labelProps=""/>
            <Center mt="sm">
              <Button variant="filled" size="md" disabled={formSubmitted} onClick={() => {setFormSubmitted(true); handleLevelRequest();}}>Submit</Button>
            </Center>
            {formSubmitted && <Group>
                <IconCircleCheckFilled color="teal"/>
                <Text>Submitted</Text>
                </Group>
            }
      </Modal>`
    </>
  );
}

function ItemContentScreen({itemScreens, numCurrentScreen, nextScreen, prevScreen}) {
  const content = itemScreens[numCurrentScreen];
  const isLast = numCurrentScreen === itemScreens.length - 1;

  // handle image while loading
  const [imgLoading, setImgLoading] = useState(true);

  // info text
  const [infoText, setInfoText] = useState(null);
  // hold the index of the current element to show. -1 is nothing, then the index corresponds to the current
  // segment (that has  info). after that, it shows the sentence translation.
  const [infoTextIndex, setInfoTextIndex] = useState(-1);

  // handle screen change
  useEffect(() => {
    window.scrollTo(0, 0);  // scroll back to top
    setInfoText(null);
    setInfoTextIndex(-1);
  }, [content]);
  useEffect(() => {
    setImgLoading(true);
  }, [content.imgUrl]);

  // enable auto play only after a button is clicked (Google Chrome policy)
  const [canAutoPlay, setCanAutoPlay] = useState(false);

  const getIndicesOfSegmentsWithInfo = (segments) => {
      return segments
        .map((segment, index) => segment[1] !== null ? index : -1)
        .filter(index => index !== -1);
  };

  var indicesOfSegmentsWithInfo = null;
  if (content.segments) {
    indicesOfSegmentsWithInfo = getIndicesOfSegmentsWithInfo(content.segments);
  }

  const handleNextButtonClick = () => {
    setCanAutoPlay(true);
    if (content.segments) {
        if (infoTextIndex < indicesOfSegmentsWithInfo.length - 1) {
            setInfoText(content.segments[indicesOfSegmentsWithInfo[infoTextIndex + 1]][1]);
            setInfoTextIndex(infoTextIndex + 1);
        }
        else if (infoTextIndex === indicesOfSegmentsWithInfo.length - 1){
            setInfoText(content.translation);
            setInfoTextIndex(infoTextIndex + 1);
        }
        else if (infoTextIndex >= indicesOfSegmentsWithInfo.length){
            nextScreen();
        }
    }
    else {
        nextScreen();
    }
  };
  const handlePrevButtonClick = () => {
    setCanAutoPlay(true);
    if (content.segments) {
        if (infoTextIndex > 0) {
            setInfoText(content.segments[indicesOfSegmentsWithInfo[infoTextIndex - 1]][1]);
            setInfoTextIndex(infoTextIndex - 1);
        }
        else if (infoTextIndex === 0){
            setInfoText(null);
            setInfoTextIndex(infoTextIndex - 1);
        }
        else if (infoTextIndex <= -1){
            prevScreen();
        }
    }
    else {
        prevScreen();
    }
  };

  return (
    <>
      {content.title && <Center><Title order={1} m="sm">{content.title}</Title></Center>}

      <Center>
        <Image src={content.imgUrl} h={300} fit="contain" onLoad={() => setImgLoading(false)} style={{display: imgLoading? "none":"block"}}/>
        {imgLoading && <Box h={300} style={{display: 'flex', alignItems: 'center'}}><Loader color="gray" type="bars" /></Box>}
      </Center>

      {content.text &&
        (<>
          <Center>
            <Stack gap="xs" m="sm">
              <Center><TextSegments segments={content.segments} highlightAll={infoTextIndex===indicesOfSegmentsWithInfo.length} highlightSegmentIndex={indicesOfSegmentsWithInfo[infoTextIndex]}/></Center>
              {infoText && <Center><Text px="xs" bg='var(--mantine-color-blue-3)'>{infoText}</Text></Center>}
            </Stack>
          </Center>
        </>)
      }

      <Center mt="md" mb="sm">
        <Button variant="outline" radius="xl" size="md" color="black" mr="sm" onClick={handlePrevButtonClick}><IconChevronLeft stroke={3}/> </Button>
        {('audio' in content) && <AudioPlayer audioUrl={content.audio} canAutoPlay={canAutoPlay}/>}
        <Button color={isLast && "green"} radius="xl" size="md" ml="sm" onClick={handleNextButtonClick}>{(isLast && infoTextIndex === indicesOfSegmentsWithInfo.length) ? "End Chapter" : <IconChevronRight stroke={3} /> }</Button>
      </Center>
      <Center><Text>Page {numCurrentScreen + 1}/{itemScreens.length}</Text></Center>
    </>
  );
}

function TextSegments({segments, highlightAll, highlightSegmentIndex}) {
    return (
        <Box bg={highlightAll ? "var(--mantine-color-blue-3)" : ""}>
            {segments.map((segment, index) => {
              return(
                <Text span key={index} bg={index === highlightSegmentIndex  ? "var(--mantine-color-blue-3)" : ""}>{segment[0]}</Text>
              );
            })}
        </Box>

    );
}

function SessionPage() {


  const { itemId, pageId } = useParams();
  const [currentItemScreen, setCurrentItemScreen] = useState(() => (parseInt(pageId, 10) - 1) || 0);

  const navigate = useNavigate();
  function sessionDone() {
    navigate("/updates");
  }

  const prevScreen = () => {
    if (currentItemScreen > 0) {
      setCurrentItemScreen((currentItemScreen) => currentItemScreen - 1);
      navigate(`/item/${itemId}/${currentItemScreen + 0}`);
    }
  };

  const nextScreen = () => {
    if (currentItemScreen < itemScreens.length - 1) {
      setCurrentItemScreen((currentItemScreen) => currentItemScreen + 1);
      navigate(`/item/${itemId}/${currentItemScreen + 2}`);
    }
    else {
      sessionDone();
    }
  };

  return (
    <>
        <Box bg="var(--mantine-color-gray-light)">
          <Center>
            <Paper shadow="sm" withBorder mb="sm" style={{ width: '600px' }} >
                <Box px="sm">
                  <ItemContentScreen itemScreens={itemScreens} numCurrentScreen={currentItemScreen} nextScreen={nextScreen} prevScreen={prevScreen}/>
                </Box>
            </Paper>
          </Center>
        </Box>
    </>
  );
}

export default SessionPage;
