import { Center, Title, Box, Paper, Text } from '@mantine/core';

function AboutPage() {

  return (
    <>
    <Box bg="var(--mantine-color-gray-light)">
      <Center>
        <Paper shadow="sm" withBorder mt="lg" mb="xl" style={{ width: '1000px' }}>
          <Box px="xl" pb="xl">
            <Center><Title order={2} p="lg">About</Title></Center>
            <Text>
                Hi!
            </Text>
            <Text mb="sm">
                As a language learning enthusiast I was always frustrated with current methods.
                They start nice, but become boring quite quickly. Those who seek more engaging content quickly find that
                it is either too difficult or not engaging. This is why the goal of Levelang is to provide engaging
                content at your level.
            </Text>
            <Text mb="sm">
                All the content is written in several difficulty levels, allowing to choose the right one for you.
                Everything is further simplified using context images, and tools for looking up words and understanding
                sentences. The content is bite-sized, made of very short and engaging chapters to make it less of a big
                deal to consume content in your target language.
            </Text>
            <Text mb="sm">
                I hope you enjoy. For any comments or questions, feel free to contact me at ehud@levelang.com.
            </Text>
            <Text>
                Yours,
            </Text>
            <Text>
                Ehud Barnea
            </Text>
          </Box>
        </Paper>
      </Center>
    </Box>
    </>
  );
}

export default AboutPage;
