import './App.css';
import { useEffect } from "react";
import { AppShell, Burger, Group, Anchor, Image, Text } from '@mantine/core';
import { useDisclosure, useFavicon } from '@mantine/hooks';
import { BrowserRouter, Routes, Route, Link, useLocation } from "react-router-dom";
import shellClasses from './AppShell.module.css';
import Homepage from './Homepage.js';
import NotFoundPage from './NotFoundPage.js';
import TosPage from './TosPage.js';
import UpdatesPage from './UpdatesPage.js';
import AboutPage from './AboutPage.js';
import PrivacyPage from './PrivacyPage.js';
import SessionPage from './SessionPage.js';


function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {
  const [opened, { toggle }] = useDisclosure(false);
  const headerHeight = 45   ;
  useFavicon('favicon.ico');

  return (
    <BrowserRouter>
      <AppShell
        header={{ height: headerHeight}}
        navbar={{ width: 300, breakpoint: 'sm', collapsed: { desktop: true, mobile: !opened } }}
      >
        <AppShell.Header style={{ backgroundColor: '#3472ed' }}>
          <Group h="100%" px="md">
            <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" className={shellClasses.burger} color="white"/>
            <Group justify="space-between" style={{ flex: 1 }}>
              <Group>
                <Link to="" className={shellClasses.logo_link}><Image src='https://levelang-public-content.s3.us-east-2.amazonaws.com/general_resources/logo.webp' h={headerHeight-10} w="auto"/></Link>
                <Link to="" className={shellClasses.logo_link}><Text size="xl" style={{color: 'white', fontWeight: 'bold'}}>Levelang</Text></Link>
              </Group>

              <Group ml="xl" gap={0} visibleFrom="sm">
                <Link to="" className={shellClasses.header_link}>Home</Link>
                <Link to="about" className={shellClasses.header_link}>About</Link>
              </Group>
            </Group>
          </Group>
        </AppShell.Header>

        <AppShell.Navbar py="md" px={4} style={{ backgroundColor: '#3472ed' }}>
          <Link to="" className={shellClasses.header_link} onClick={() => toggle()}>Home</Link>
          <Link to="about" className={shellClasses.header_link} onClick={() => toggle()}>About</Link>
        </AppShell.Navbar>

        <AppShell.Main className={shellClasses.main}>
          <ScrollToTop />
          <Routes>
            <Route index element={<Homepage />} />
            <Route path="updates" element={<UpdatesPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="privacy" element={<PrivacyPage />} />
            <Route path="tos" element={<TosPage />} />
            <Route path="item/:itemId" element={<SessionPage />} />
            <Route path="item/:itemId/:pageId" element={<SessionPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>


        </AppShell.Main>
      </AppShell>
    </BrowserRouter>
  );
}

export default App;
