import { Center, Title, Box, Paper, Text, Button } from '@mantine/core';
import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <>
    <Box bg="var(--mantine-color-gray-light)">
      <Center>
        <Paper shadow="sm" withBorder mt="lg" mb="xl" style={{ width: '800px' }}>
          <Box px="xl" py="lg">
            <Center><Title order={2} pb="lg">Page not found (404)</Title></Center>
            <Text>The page you are trying to open does not exist. You may have mistyped the address, or the page has been moved to another URL. If you think this is an error, please contact support.</Text>
            <Center>
                <Link to="">
                  <Button size="md" mt="lg">Get back to home page</Button>
                </Link>
            </Center>
          </Box>
        </Paper>
      </Center>
    </Box>
    </>
  );
}

export default NotFoundPage;
