import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { init as initFullStory } from '@fullstory/browser';
import reportWebVitals from './reportWebVitals';
import { MantineProvider } from '@mantine/core';
import '@mantine/core/styles.css';

//const myColor: MantineColorsTuple = [
//  "#e0fbff",
//  "#cbf2ff",
//  "#9ae2ff",
//  "#64d2ff",
//  "#3cc5fe",
//  "#23bcfe",
//  "#09b8ff",
//  "#00a1e4",
//  "#0090cd",
//  "#007cb5"
//];
 
//const theme = createTheme({
//  primaryColor: 'primary',
//  colors: {
//    'primary': myColor,
//  }
//});

initFullStory({ orgId: '15TQHQ' });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <MantineProvider >
    <React.StrictMode>
        <App />
    </React.StrictMode>
  </MantineProvider>
);
  
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
